
import { Vue } from "vue-class-component";
import {getSystemByIdRequest, saveSystemRequest} from "@/services/ObjectService";
import Swal from "sweetalert2";

export default class ObjectSystemData extends Vue {
	system: any = {
		id: "",
		number: "",
		name: ""
	}
	
	get systemId() {
		return this.$route.params.system
	}
	
	saveSystemData() {
		const data = {
			id: this.systemId,
			name: this.system.name
		}
		
		saveSystemRequest(data).then((res: any) => {
			const message = res?.result?.message ? res?.result?.message : 'Anlage wurde erfolgreich umbenannt';
			const icon = res?.result?.status == 404 ? 'error' : 'success';
			if ( res.status != "OK" ) return false
			
			Swal.fire({
				html: message,
				icon: icon,
				confirmButtonText: 'OK',
				buttonsStyling: false,
				customClass: {
					confirmButton: 'btn btn-primary'
				}
			})
		})
	}
	
	beforeMount() {
		const data = {
			id: this.systemId
		}
		getSystemByIdRequest(data).then((res: any) => {
			if ( res.status != "OK" ) return false
			
			this.system.id = res.result.anlage_id
			this.system.number = res.result.anlage_nummer
			this.system.name = res.result.anlage_bemerkung
		})
	}
}
